import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Logo from "../components/Logo"
import {
	getAuth,
	GoogleAuthProvider,
	TwitterAuthProvider,
	EmailAuthProvider,
} from "firebase/auth"
import { getFirestore, doc, getDoc } from "firebase/firestore"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

import { Item } from "../components/Game/Scoreboard"
import { SecondaryButton } from "../components/Buttons"
import Loading from "../components/Loading"
import { cookieLink, privacyLink, URLConversion } from "../components/Constants"
const auth = getAuth()

const ProfilePage = () => {
	const [user, setUser] = React.useState(auth.currentUser)
	const [questionsPlayed, setQuestionsPlayed] = React.useState(0)
	const [allTimeScore, setAllTimeScore] = React.useState(0)
	const [recentlyPlayed, setRecentlyPlayed] = React.useState([])
	const [highScoreScore, setHighScoreScore] = React.useState(0)
	const [highScoreCategory, setHighScoreCategory] = React.useState("")
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser)
		})
		return () => unregisterAuthObserver()
	}, [])

	React.useEffect(() => {
		const asyncFunction = async (uid: string) => {
			const UserDataRef = doc(getFirestore(), `UserData/${uid}`)
			const UserData = await getDoc(UserDataRef)
			if (UserData.exists()) {
				const data = UserData.data()
				if (typeof data !== "undefined") {
					data.questionsPlayed && setQuestionsPlayed(data.questionsPlayed)
					data.allTimeScore && setAllTimeScore(data.allTimeScore)
					data.recentlyPlayed && setRecentlyPlayed(data.recentlyPlayed)
					data.highScore && setHighScoreScore(data.highScore.score)
					data.highScore && setHighScoreCategory(data.highScore.category)
					setLoading(false)
				}
			}
		}

		if (user) {
			asyncFunction(user.uid)
		} else {
			setQuestionsPlayed(0)
			setAllTimeScore(0)
			setRecentlyPlayed([])
			setHighScoreScore(0)
			setHighScoreCategory("")
		}
	}, [user])

	return (
		<>
			<Helmet>
				<title>
					Google Feud - {user && !user.isAnonymous ? "Profile" : "Sign In"}
				</title>
			</Helmet>
			<Logo />
			<h2>
				{user && !user.isAnonymous
					? `Profile Page: ${user.displayName}`
					: "Register / Sign In"}
			</h2>
			{user && !user.isAnonymous ? (
				loading ? (
					<Loading />
				) : (
					<>
						<ul>
							<li key="questions">
								<Item
									scoreName={"Total Questions Played"}
									scoreValue={questionsPlayed}
								/>
							</li>
							<li key="points">
								<Item
									highScore
									scoreName={"Total Points"}
									scoreValue={allTimeScore}
								/>
							</li>
							<li key="highscore">
								<Item
									scoreName={`High Score${
										highScoreCategory && ` (${highScoreCategory})`
									}`}
									scoreValue={highScoreScore}
								/>
							</li>
							{recentlyPlayed.length > 0 && (
								<li key="recent">
									<Item
										scoreName={"Recently Played Questions"}
										scoreValue={
											<ul style={{ marginTop: "calc(-1 * var(--half))" }}>
												{recentlyPlayed.slice(-10).map((question) => (
													<li
														key={question}
														className="altList"
														style={{
															margin: "var(--half)",
														}}>
														<Link to={`../answers/${URLConversion(question)}/`}>
															{question}
														</Link>
													</li>
												))}
											</ul>
										}
									/>
								</li>
							)}
						</ul>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<SecondaryButton text="Sign Out" onClick={() => auth.signOut()} />
						</div>
					</>
				)
			) : (
				<>
					<p>
						Sign in to Google Feud to keep track of played questions and high
						scores, see answers and more!
					</p>
					<StyledFirebaseAuth
						uiConfig={{
							//autoUpgradeAnonymousUsers: true,
							signInFlow: "popup",
							signInOptions: [
								GoogleAuthProvider.PROVIDER_ID,
								TwitterAuthProvider.PROVIDER_ID,
								EmailAuthProvider.PROVIDER_ID,
							],
							tosUrl: cookieLink,
							privacyPolicyUrl: privacyLink,
							callbacks: {
								signInSuccessWithAuthResult: () => false,
								signInFailure: (error) => {
									if (
										error.code === "firebaseui/anonymous-upgrade-merge-conflict"
									) {
										return console.log(error)
									}
									return console.log(error)
								},
							},
						}}
						firebaseAuth={auth}
					/>
				</>
			)}
		</>
	)
}
export default ProfilePage
