import React from "react"
import { motion } from "framer-motion"

const Loading = () => (
	<motion.h2
		animate={{ opacity: 0 }}
		style={{
			opacity: 0.5,
		}}
		transition={{
			ease: "easeInOut",
			duration: 0.6,
			delay: 0.2,
			repeat: Infinity,
			repeatType: "reverse",
		}}>
		Loading...
	</motion.h2>
)

export default Loading
